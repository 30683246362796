import { defineComponent } from 'vue'

import scrollToSelfMixin from '~/mixins/shared/scrollToSelf'

import type { PropType } from 'vue'

/**
 * @deprecated
 */
export default defineComponent({
  mixins: [scrollToSelfMixin],
  props: {
    /**
     * @deprecated
     */
    pValidity: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default() {
        return undefined
      },
    },
    /**
     * @deprecated
     */
    pIsValidating: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default() {
        return undefined
      },
    },
    /**
     * @deprecated
     */
    pApplyValidity: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      /**
       * @deprecated
       */
      PRIVATE_VALIDITY: true,
      /**
       * @deprecated
       */
      PRIVATE_IS_VALIDATING: false,
    }
  },
  computed: {
    /**
     * @deprecated
     */
    validity(): boolean {
      return this.pValidity ?? this.PRIVATE_VALIDITY
    },
    /**
     * @deprecated
     */
    isValidating(): boolean {
      return this.pIsValidating ?? this.PRIVATE_IS_VALIDATING
    },
  },
})
