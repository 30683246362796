import { defineComponent } from 'vue'

import type { PropType } from 'vue'

export type ScrollToSelfOverride = (
  offset: number,
  container: HTMLElement | null,
) => void

export default defineComponent({
  props: {
    scrollToSelfOverride: {
      type: Function as PropType<ScrollToSelfOverride | undefined>,
      required: false,
      default: undefined,
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    scrollToSelf(offset = 0) {
      // @ts-ignore Trust
      const container = window.document.getElementById(this._uid)

      offset = offset || this.offset
      if (typeof this.scrollToSelfOverride === 'undefined') {
        if (container) {
          const containerOffset = container.getBoundingClientRect().top
          const documentOffset = window.document
            .getElementsByTagName('body')[0]
            .getBoundingClientRect().top

          window.scrollTo({
            top: containerOffset - documentOffset - offset,
            left: 0,
            behavior: 'smooth',
          })
        }
      } else this.scrollToSelfOverride(offset, container)
    },
  },
})
